const state = () => ({
  availableSelectiveProcesses: [],
  mySelectiveProcesses: [],
  favoriteSelectiveProcesses: [],
  allSelectiveProcesses: [],
})

const getters = {
  /**
   * @returns A list of selective processes for the candidate
   */
  availableSelectiveProcesses: (state) =>
    state.availableSelectiveProcesses.results,
  mySelectiveProcesses: (state) => state.mySelectiveProcesses,
  favoriteSelectiveProcesses: (state) => state.favoriteSelectiveProcesses,
  allSelectiveProcesses: (state) => state.allSelectiveProcesses,
}

const actions = {
  subscribeForAJob(context, id) {
    const headers = {
      Authorization: this._vm.token,
    }
    const url = `/selective-processes/${id}/subscribe`

    return this._vm.$axios.post(url, {}, { headers }).then(({ data }) => {
      return Promise.resolve(data)
    })
  },
  unsubscribeForAJob(context, id) {
    const headers = {
      Authorization: this._vm.token,
    }
    const url = `/selective-processes/${id}/unsubscribe`

    return this._vm.$axios.post(url, { headers }).then(({ data }) => {
      return Promise.resolve(data)
    })
  },
  getSelectiveProcessSteps(context, id) {
    const headers = {
      Authorization: this._vm.token,
    }
    const url = `/selective-processes/subscribed/${id}/stages`

    return this._vm.$axios.get(url, { headers }).then(({ data }) => {
      return Promise.resolve(data)
    })
  },

  getSelectiveProcessesThatImSubscribed({ commit }) {
    const headers = {
      Authorization: this._vm.token,
    }
    const url = '/selective-processes/subscribed'

    return this._vm.$axios.get(url, { headers }).then(({ data }) => {
      commit('setMySelectiveProcesses', data)
      return Promise.resolve(data)
    })
  },

  getSelectiveProcessThatImSubscribed(context, id) {
    const headers = {
      Authorization: this._vm.token,
    }
    const url = `/selective-processes/subscribed/${id}`

    return this._vm.$axios.get(url, { headers })
  },

  getAvailableSelectiveProcesses({ commit }, params = {}) {
    const headers = {
      Authorization: this._vm.token,
    }
    const url = '/selective-processes/available'

    return this._vm.$axios.get(url, { params, headers }).then(({ data }) => {
      commit('setAvailableSelectiveProcesses', data)
      return Promise.resolve(data)
    })
  },

  getAvailableSelectiveProcess(context, id) {
    const headers = {
      Authorization: this._vm.token,
    }
    const url = `/selective-processes/available/${id}`

    return this._vm.$axios.get(url, { headers })
  },

  getFavoriteSelectiveProcesses({ commit }, params = {}) {
    const headers = {
      Authorization: this._vm.token,
    }
    const url = '/selective-processes/favorites'

    return this._vm.$axios.get(url, { headers, params }).then(({ data }) => {
      commit('setFavoriteSelectiveProcesses', data)
      return Promise.resolve(data)
    })
  },

  getAllSelectiveProcesses({ commit }, params = {}) {
    const headers = {
      Authorization: this._vm.token,
    }
    const url = '/selective-processes/available/list'

    return this._vm.$axios.get(url, { headers, params }).then(({ data }) => {
      commit('setAllSelectiveProcesses', data)
      return Promise.resolve(data)
    })
  },

  getAllSelectiveProcess(context, id) {
    const headers = {
      Authorization: this._vm.token,
    }
    const url = `/selective-processes/available/list/${id}`

    return this._vm.$axios.get(url, { headers })
  },
}

const mutations = {
  setAvailableSelectiveProcesses(state, selective_processes) {
    state.availableSelectiveProcesses = selective_processes
  },
  setMySelectiveProcesses(state, selective_processes) {
    state.mySelectiveProcesses = selective_processes
  },
  setFavoriteSelectiveProcesses(state, selective_processes) {
    state.favoriteSelectiveProcesses = selective_processes
  },
  setAllSelectiveProcesses(state, selective_processes) {
    state.allSelectiveProcesses = selective_processes
  },
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
}
