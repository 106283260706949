const state = () => ({
  questionnaires: [],
})

const getters = {
  questionnaires: (state) => state.questionnaires.results,
  totalQuestionnaires: (state) => state.questionnaires.total_items,
}

const actions = {
  getQuestionnaires({ commit }, params = {}) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios
      .get('/questionnaires/', { params, headers })
      .then((response) => {
        commit('setQuestionnaires', response.data)
        return Promise.resolve(response.data)
      })
  },

  getQuestionnaire(context, id) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios.get(`/questionnaires/${id}`, { headers })
  },

  getQuestions(context, id) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios.get(`/questionnaires/${id}/questions`, { headers })
  },

  createQuestionnaire(context, questionnaire) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios.post('/questionnaires/', questionnaire, { headers })
  },

  editQuestion(context, settings) {
    const { id } = settings

    const headers = {
      Authorization: this._vm.token,
    }

    delete settings['id']

    return this._vm.$axios.put(`/questions/${id}`, settings, {
      headers,
    })
  },

  deleteQuestion(context, payload) {
    const headers = {
      Authorization: this._vm.token,
    }

    const id = payload.id
    return this._vm.$axios.post(
      `/questionnaires/${id}/rem_questions`,
      payload.questions,
      { headers }
    )
  },

  editQuestionnaire(context, { id, questionnaire }) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios.put(`/questionnaires/${id}`, questionnaire, {
      headers,
    })
  },

  deleteQuestionnaires({ dispatch, commit }, id) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios
      .delete(`/questionnaires/${id}`, { headers })
      .then(() => {
        dispatch('getQuestionnaires')
        commit(
          'setSnackbarMessage',
          {
            message: 'O questionário foi excluído',
            color: 'success',
          },
          { root: true }
        )
      })
      .catch((error) => {
        console.error('deleteQuestionnaires', error)
        commit(
          'setSnackbarMessage',
          {
            message: 'Erro ao excluir o questionário solicitado',
            color: 'error',
          },
          { root: true }
        )
      })
  },

  getAlternatives(context, id) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios.get(`/questions/${id}/alternatives`, { headers })
  },

  createAlternative(context, payload) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios.post(
      `/questions/${payload.id}/alternatives`,
      payload.alternatives,
      { headers }
    )
  },

  addAlternative(context, payload) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios.post(
      `/questions/${payload.question_id}/alternatives/${payload.alternative_id}`,
      payload.alternatives,
      { headers }
    )
  },

  createQuestion(context, payload) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios.post(
      `/questionnaires/${payload.id}/questions`,
      payload.question,
      { headers }
    )
  },
}

const mutations = {
  setQuestionnaires(state, questionnaires) {
    state.questionnaires = questionnaires
  },
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
}
