const state = () => ({
  companies: [],
  companiesNoPaginated: [],
})

const getters = {
  companies: (state) => state.companies.results,
  totalCompanies: (state) => state.companies.count,
  companiesNoPaginated: (state) => state.companiesNoPaginated,
}

const actions = {
  getCompanies({ commit }) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios.get('/companies', { headers }).then((response) => {
      commit('setCompanies', response.data)
      return Promise.resolve(response.data)
    })
  },
  getCompaniesNoPaginated({ commit }) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios
      .get('/companies/?no_paginate=true', { headers })
      .then((response) => {
        commit('setCompaniesNoPaginated', response.data)
        return Promise.resolve(response.data)
      })
  },
}

const mutations = {
  setCompanies(state, companies) {
    state.companies = companies
  },
  setCompaniesNoPaginated(state, companies) {
    state.companiesNoPaginated = companies
  },
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
}
