const state = () => ({
  selectiveProcesses: [],
  candidatesOfSelectiveProcess: [],
  add: false,
  manage: true,
  currentSelectiveProcess:
    localStorage.getItem('currentSelectiveProcess') || null,
  criteria: [],
})

const getters = {
  selectiveProcesses: (state) => state.selectiveProcesses.results,
  totalSelectiveProcesses: (state) => state.selectiveProcesses.total_items,
  totalPagesSelectiveProcesses: (state) => state.selectiveProcesses.total_pages,
  openSelectiveProcessesNumber: (state) =>
    state.selectiveProcesses.results?.filter(
      (selective_process) => selective_process.status === 'Em Execução'
    ).length,
  closedSelectiveProcessesNumber: (state) =>
    state.selectiveProcesses.results?.filter((selective_process) =>
      ['Finalizado com Contratação', 'Finalizado sem Contratação'].includes(
        selective_process.status
      )
    ).length,
  manageSelectiveProcesses: (state) => state.selectiveProcesses.manage,
  addSelectiveProcesses: (state) => state.selectiveProcesses.add,
  currentSelectiveProcess: (state) => state.currentSelectiveProcess,
  candidatesOfSelectiveProcess: (state) => state.candidatesOfSelectiveProcess,
  criteria: (state) => state.criteria,
}

const actions = {
  deleteStage(context, stageId) {
    const headers = {
      Authorization: this._vm.token,
    }
    const url = `/stages/${stageId}`
    return this._vm.$axios.delete(url, { headers })
  },

  editStage(context, { stepId, payload }) {
    const headers = {
      Authorization: this._vm.token,
    }
    const url = `/stages/${stepId}`

    return this._vm.$axios.put(url, payload, { headers })
  },

  addStage(context, { selectiveProcessId, payload }) {
    const headers = {
      Authorization: this._vm.token,
    }
    const url = `/selective-processes/${selectiveProcessId}/stages`

    return this._vm.$axios.post(url, payload, { headers })
  },

  getStages(context, selectiveProcessId) {
    const headers = {
      Authorization: this._vm.token,
    }
    const url = `/selective-processes/${selectiveProcessId}/stages`

    return this._vm.$axios.get(url, { headers })
  },

  async preSelectCandidates(context, { selectiveProcessId, candidateIds }) {
    const headers = {
      Authorization: this._vm.token,
    }
    const payload = {
      candidates: candidateIds,
    }
    const url = `/selective-processes/${selectiveProcessId}/pre-select`

    try {
      const response = await this._vm.$axios.post(url, payload, { headers })
      if (response.status === 204) {
        console.log(
          'No Content: The request was successful but there is no additional content to send in the response.'
        )
      } else if (response.status === 201) {
        console.log('Created: Candidate stages were successfully created.')
      } else {
        console.log('response: ', response)
      }
      return response
    } catch (error) {
      console.error('Erro ao pré-selecionar candidatos: ', error)
      console.error('error.response: ', error.response)
      throw error
    }
  },

  addCandidatesToStage(context, { stepId, candidateIds }) {
    const headers = {
      Authorization: this._vm.token,
    }
    const payload = {
      candidates: candidateIds,
    }
    const url = `/stages/${stepId}/register-candidates`

    return this._vm.$axios.post(url, payload, { headers })
  },

  getCandidatesBySelectiveProcess(context, id) {
    const headers = {
      Authorization: this._vm.token,
    }
    const url = `/selective-processes/${id}/candidate-stages`

    return this._vm.$axios
      .get(url, { headers })
      .then(({ data }) => Promise.resolve(data))
  },

  getCandidatesByStage(context, id) {
    const headers = {
      Authorization: this._vm.token,
    }
    const url = `/stages/${id}/candidate-stages`

    return this._vm.$axios
      .get(url, { headers })
      .then(({ data }) => Promise.resolve(data))
  },

  editSelectiveProcess({ commit, dispatch }, payload) {
    const headers = {
      Authorization: this._vm.token,
    }
    if (payload.image) {
      headers['Content-Type'] = 'multipart/form-data'
    }
    const id = payload.id
    delete payload['id']
    return this._vm.$axios
      .patch(`/selective-processes/${id}`, payload, { headers })
      .then((response) => {
        dispatch('getSelectiveProcesses', response.data)
        commit('setCurrentSelectiveProcess', response.data.id)
        return Promise.resolve(response.data)
      })
  },

  createSelectiveProcess(context, { payload, headers = {} }) {
    const _headers = {
      Authorization: this._vm.token,
      ...headers,
    }
    return this._vm.$axios
      .post('/selective-processes/', payload, { headers: _headers })
      .then(({ data }) => data)
  },

  getSelectiveProcesses({ commit }, params = {}) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios
      .get('/selective-processes/', { params, headers })
      .then((response) => {
        commit('setSelectiveProcess', response.data)
        return Promise.resolve(response.data)
      })
  },

  getSelectiveProcess(context, id) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios
      .get(`/selective-processes/${id}`, { headers })
      .then((response) => {
        return Promise.resolve(response.data)
      })
  },

  getCandidateResume(context, { selectiveProcessId, candidateId }) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios
      .get(
        `/selective-processes/${selectiveProcessId}/candidate-resume/${candidateId}`,
        { headers }
      )
      .then(({ data }) => data)
  },

  getCandidateSubmission(context, { stageId, candidateId }) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios
      .get(`/stages/${stageId}/candidate-submission/${candidateId}`, {
        headers,
      })
      .then(({ data }) => data)
  },

  getCandidateQuestionaryAnswer(context, { stageId, candidateId }) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios
      .get(`/stages/${stageId}/candidate-questionary-answer/${candidateId}`, {
        headers,
      })
      .then(({ data }) => data)
  },

  putCandidateQuestionaryAnswer(context, { stageId, candidateId, payload }) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios
      .put(
        `/stages/${stageId}/candidate-questionary-answer/${candidateId}`,
        payload,
        {
          headers,
        }
      )
      .then(({ data }) => data)
  },

  getCandidateInterview(context, { stageId, candidateId }) {
    const headers = {
      Authorization: this._vm.token,
    }

    return this._vm.$axios
      .get(`/stages/${stageId}/candidate-interview/${candidateId}`, {
        headers,
      })
      .then(({ data }) => {
        return Promise.resolve(data)
      })
  },

  postCandidateInterview(context, { stageId, candidateId, payload }) {
    const headers = {
      Authorization: this._vm.token,
    }

    return this._vm.$axios
      .post(`/stages/${stageId}/candidate-interview/${candidateId}/`, payload, {
        headers,
      })
      .then(({ data }) => data)
  },

  //postCandidateAnnotations(context, { stageId, candidateId, payload }) {
  //  const headers = {
  //    Authorization: this._vm.token,
  //  }

  //  return this._vm.$axios
  //    .post(
  //      `/stages/${stageId}/candidate-interview/${candidateId}/annotations/`,
  //      payload,
  //      {
  //        headers,
  //      }
  //    )
  //    .then(({ data }) => data)
  //},

  approveCandidate({ dispatch }, { stageId, candidateId }) {
    return dispatch('approveCandidates', {
      stageId,
      candidateIds: [candidateId],
    })
  },

  approveCandidates(context, { stageId, candidateIds }) {
    const headers = {
      Authorization: this._vm.token,
    }
    const payload = {
      candidates: candidateIds,
    }
    const url = `/stages/${stageId}/approve-candidates`
    return this._vm.$axios.post(url, payload, { headers })
  },

  hireCandidate(context, { stageId, candidateId }) {
    const headers = {
      Authorization: this._vm.token,
    }
    const payload = {
      candidates: [candidateId],
    }
    const url = `/stages/${stageId}/hire-candidates`
    return this._vm.$axios.post(url, payload, { headers })
  },

  getCriteria({ commit }) {
    const headers = {
      Authorization: this._vm.token,
    }

    return this._vm.$axios
      .get('/evaluation-criteria/', { headers })
      .then((response) => {
        commit('setCriteria', response.data)
        return Promise.resolve(response.data)
      })
  },
}

const mutations = {
  setSelectiveProcess(state, selective_process) {
    state.selectiveProcesses = selective_process
  },
  setAddSelectiveProcess(state, status) {
    state.add = status
    state.manage = !status
  },
  setManageSelectiveProcess(state, status) {
    state.manage = status
    state.add = !status
  },
  setCurrentSelectiveProcess(state, id) {
    if (id == null) {
      localStorage.removeItem('currentSelectiveProcess')
      state.currentSelectiveProcess = null
    } else {
      state.currentSelectiveProcess = id
      localStorage.setItem('currentSelectiveProcess', id)
    }
  },
  setAvailableSelectiveProcesses(selective_processes) {
    state.availableSelectiveProcesses = selective_processes
  },
  setCriteria(state, criteria) {
    state.criteria = criteria
  },
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
}
