const state = () => ({
  recruiterInterviews: [],
  recruiterInterview: {},
  candidateEvaluation: {},
  candidatesEvaluated: [],
})

const getters = {
  recruiterInterviews: (state) => state.recruiterInterviews,
  recruiterInterview: (state) => state.recruiterInterview,
  candidateEvaluation: (state) => state.candidateEvaluation,
  candidatesEvaluated: (state) => state.candidatesEvaluated,
}

const actions = {
  getRecruiterInterviews({ commit }) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios
      .get('/selective-processes/interview-recruiter', { headers })
      .then((response) => {
        commit('setRecruiterInterviews', response.data)
        return Promise.resolve(response.data)
      })
  },
  postCriteriaRecruiterInterview(context, { payload }) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios
      .post('/selective-processes/interview-recruiter', payload, { headers })
      .then((response) => {
        return Promise.resolve(response.data)
      })
  },
  deleteCriteriaRecruiterInterview(context, { idStage }) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios
      .delete(`/selective-processes/interview-recruiter/${idStage}`, {
        headers,
      })
      .then((response) => {
        return Promise.resolve(response.data)
      })
  },
  patchRecruiterInterview(context, { idStage, payload }) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios
      .patch(`/selective-processes/interview-recruiter/${idStage}`, payload, {
        headers,
      })
      .then((response) => {
        return Promise.resolve(response.data)
      })
  },
  getRecruiterInterview({ commit }, { idStage }) {
    const headers = {
      Authorization: this._vm.token,
    }

    return this._vm.$axios
      .get(`/selective-processes/interview-recruiter/${idStage}`, { headers })
      .then((response) => {
        commit('setRecruiterInterview', response.data)
        return Promise.resolve(response.data)
      })
  },
  postCandidateEvaluation(context, { idStage, payload }) {
    const headers = {
      Authorization: this._vm.token,
    }

    return this._vm.$axios
      .post(
        `/selective-processes/interview-recruiter/${idStage}/candidate-evaluation`,
        payload,
        { headers }
      )
      .then((response) => {
        return Promise.resolve(response.data)
      })
  },
  getCandidateEvaluation({ commit }, { idStage, idUser }) {
    const headers = {
      Authorization: this._vm.token,
    }

    return this._vm.$axios
      .get(
        `/selective-processes/interview-recruiter/${idStage}/candidate-evaluation/${idUser}`,
        { headers }
      )
      .then((response) => {
        commit('setCandidateEvaluation', response.data)
        return Promise.resolve(response.data)
      })
  },
  patchCandidateEvaluation(context, { idStage, idUser, payload }) {
    const headers = {
      Authorization: this._vm.token,
    }

    return this._vm.$axios
      .patch(
        `/selective-processes/interview-recruiter/${idStage}/candidate-evaluation/${idUser}`,
        payload,
        { headers }
      )
      .then((response) => {
        return Promise.resolve(response.data)
      })
  },
  getCandidatesEvaluated({ commit }, { idStage }) {
    const headers = {
      Authorization: this._vm.token,
    }

    return this._vm.$axios
      .get(
        `/selective-processes/interview-recruiter/${idStage}/candidate-evaluation`,
        {
          headers,
        }
      )
      .then((response) => {
        commit('setCandidatesEvaluated', response.data)
        return Promise.resolve(response.data)
      })
  },
  deleteEvaluatedCandidate(context, { idStage, idUser }) {
    const headers = {
      Authorization: this._vm.token,
    }

    return this._vm.$axios
      .delete(
        `/selective-processes/interview-recruiter/${idStage}/candidate-evaluation/${idUser}`,
        { headers }
      )
      .then((response) => {
        return Promise.resolve(response.data)
      })
  },
}

const mutations = {
  setRecruiterInterviews(state, recruiterInterviews) {
    state.recruiterInterviews = recruiterInterviews
  },
  setRecruiterInterview(state, recruiterInterview) {
    state.recruiterInterview = recruiterInterview
  },
  setCandidateEvaluation(state, candidateEvaluation) {
    state.candidateEvaluation = candidateEvaluation
  },
  setCandidatesEvaluated(state, candidatesEvaluated) {
    state.candidatesEvaluated = candidatesEvaluated
  },
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
}
